import React from 'react'
import { usePageIconsImages } from 'hooks/usePageIconsImagesHook'

import Intro from 'elements/Intro'
import { StaticImage } from 'gatsby-plugin-image'

const PartnersContent = () => {
  const {
    partnersIcon: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src }
          }
        }
      }
    }
  } = usePageIconsImages()
  return (
    <section className="py-16">
      <div className="container mx-auto px-8">
        <Intro pageTitle="Partners" imgSrc={src} />
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-5 mt-16 z-1 relative text-xs">
          <div className="inline-flex flex-col">
            <div>
              <StaticImage
                className="w-32 h-32 shadow-news-card rounded-lg"
                src="../../images/partners/P05(CHINA-CEE).png"
                alt="Center for Study of Civilizations"
                placeholder="hidden"
                quality="100"
              />
            </div>
            <a className="hover:underline" href="https://china-cee.eu" target="_blank" rel="noopener">
              <span className="block w-36 mt-4">China-CEE Institute 中国——中东欧研究院</span>
            </a>
          </div>
          <div className="inline-flex flex-col">
            <div className="flex items-center w-32 h-32 bg-white rounded-lg shadow-news-card">
              <StaticImage
                className="object-contain w-full  rounded-lg"
                src="../../images/partners/P06(GEOFO).jpg"
                alt="Geoeconomic Forum"
                placeholder="hidden"
                quality="100"
              />
            </div>
            <a
              className="hover:underline"
              href="https://www.geoeconomic-forum.com/en/council.html"
              target="_blank"
              rel="noopener"
            >
              <span className="block w-36 mt-4">Geoeconomic Forum (GEOFO)</span>
            </a>
          </div>
          <div className="inline-flex flex-col">
            <div>
              <StaticImage
                className="w-32 h-32 shadow-news-card rounded-lg"
                src="../../images/partners/P07(Jasna Plevnik).png"
                alt="Dr. Jasna Plevnik"
                placeholder="hidden"
                quality="100"
              />
            </div>
            <a
              className="hover:underline"
              href="https://www.geoeconomic-forum.com/en/council.html"
              target="_blank"
              rel="noopener"
            >
              <span className="block w-36 mt-4">Dr. Jasna Plevnik Geoeconomic Forum (GEOFO)</span>
            </a>
          </div>
          <div className="inline-flex flex-col">
            <div>
              <StaticImage
                className="w-36 h-32 shadow-news-card rounded-lg"
                src="../../images/partners/P08(CSEBA).png"
                alt="Chinese Southeast European Business Association"
                placeholder="hidden"
                quality="100"
              />
              q
            </div>
            <a className="hover:underline" href="http://www.cseba.eu" target="_blank" rel="noopener">
              <span className="block w-36 mt-4">
                Chinese Southeast European Business Association (CSEBA) 中国东南欧洲商业协会
              </span>
            </a>
          </div>
          <div className="inline-flex flex-col">
            <div>
              <StaticImage
                className="w-32 h-32 shadow-news-card rounded-lg"
                src="../../images/partners/P09(Peng Yuanchao).png"
                alt="Prof. Peng Yuchao"
                placeholder="hidden"
                quality="100"
              />
            </div>
            <span className="block w-36 mt-4">
              Prof. Peng Yuchao (彭裕超) (Croatian language department Beijing Foreign Language University
              北京外国语大学)
            </span>
          </div>
          <div className="inline-flex flex-col">
            <div>
              <StaticImage
                className="w-32 h-32 shadow-news-card rounded-lg"
                src="../../images/partners/P10(Konf).png"
                alt="Confucius Institute University of Zagreb"
                placeholder="hidden"
                quality="100"
              />
            </div>
            <span className="block w-36 mt-4">Confucius Institute University of Zagreb</span>
          </div>
          <div className="inline-flex flex-col">
            <div>
              <StaticImage
                className="w-32 h-32 shadow-news-card rounded-lg"
                src="../../images/partners/P11(ISP).jpg"
                alt="Institute for Security Policies"
                placeholder="hidden"
                quality="100"
              />
            </div>
            <a className="hover:underline" href="http://insigpol.hr/en/home-page/" target="_blank" rel="noopener">
              <span className="block w-36 mt-4">Institute for Security Policies</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PartnersContent
