import React from 'react'

import PartnersContent from 'components/Partners'
import SEO from 'components/SEO'

const Partners = () => {
  return (
    <>
      <SEO title="Partners" />
      <PartnersContent />
    </>
  )
}

export default Partners
